// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".newsletter-form______1mlr4{border-bottom:2px solid #fff}.newsletter-form______1mlr4 .newsletter-form__reveal_wrapper___32MQi{display:-webkit-box;display:-ms-flexbox;display:flex;width:90%}.newsletter-form______1mlr4 .newsletter-form__row___3vkfc{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;padding-bottom:3px}.newsletter-form______1mlr4 .newsletter-form__form___3qR6U{-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;display:-webkit-box;display:-ms-flexbox;display:flex}.newsletter-form______1mlr4 .newsletter-form__newsletter_input___3fpME input{border:none;padding:0;background-color:transparent;font-size:12px}", "",{"version":3,"sources":["webpack://./src/styles/partials/forms/newsletter-form.scss"],"names":[],"mappings":"AAEA,4BACE,4BAAA,CAEA,qEACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,SAAA,CAGF,0DACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,qBAAA,CAAA,kBAAA,CAAA,oBAAA,CACA,kBAAA,CAEF,2DACE,qBAAA,CAAA,kBAAA,CAAA,oBAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CAIA,6EACE,WAAA,CACA,SAAA,CACA,4BAAA,CACA,cAAA","sourcesContent":["@import \"../../global/parts\";\n\n._ {\n  border-bottom: 2px solid white;\n\n  .reveal_wrapper {\n    display: flex;\n    width: 90%;\n  }\n\n  .row {\n    display: flex;\n    align-items: flex-end;\n    padding-bottom: 3px;\n  }\n  .form {\n    align-items: flex-end;\n    display: flex;\n  }\n\n  .newsletter_input {\n    input {\n      border: none;\n      padding: 0;\n      background-color: transparent;\n      font-size: 12px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_": "newsletter-form______1mlr4",
	"reveal_wrapper": "newsletter-form__reveal_wrapper___32MQi",
	"row": "newsletter-form__row___3vkfc",
	"form": "newsletter-form__form___3qR6U",
	"newsletter_input": "newsletter-form__newsletter_input___3fpME"
};
export default ___CSS_LOADER_EXPORT___;
