import React from "react";
import { _classes } from "../../utilities/helpers";
import styles from "../../../styles/partials/forms/newsletter-form.scss";
import { FormProvider, StandardInput, SubmitInput } from "../../form";
import Reveal from "../../widgets/Reveal";

const cl = _classes(styles);
export default function NewsletterForm() {
  return (
    <div className={cl("_")}>
      <FormProvider onSubmit={"newsletter"}>
        <Reveal className={cl("reveal_wrapper")}>
          <StandardInput
            name="email"
            placeholder="Sign up for our newsletter"
            rules={{ required: "Please enter a valid email." }}
            className={cl("newsletter_input")}
            labelVisible={false}
          />
        </Reveal>
        <div className={cl("row")}>
          <SubmitInput className={cl("newsletter_submit")} />
        </div>
      </FormProvider>
    </div>
  );
}
