import { playIconSVG } from "../widgets/SVG";
SubmitField.propTypes = {
  text: PropTypes.string,
};

SubmitField.defaultProps = {
  text: "Submit",
};

export default function SubmitField({ text }) {
  return (
    <div className={"field field-submit"}>
      <div className={"control"}>
        <button className="submit">{playIconSVG("12px", "7px")}</button>
      </div>
    </div>
  );
}
