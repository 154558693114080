import { _classes } from "../utilities/helpers";
import styles from "../../styles/partials/footer.scss";
import { useAppState } from "../providers";
import { byPaliSocietyLogo, instagram, mainLogo } from "../widgets/SVG";
import NewsletterForm from "./forms/NewsletterForm";
import Link from "../widgets/Link";
import { motion, AnimatePresence } from "framer-motion";
import { useViewportContext } from "../providers";

const cl = _classes(styles);

Footer.propTypes = {};
function Footer() {
  const { viewport } = useViewportContext();
  const isMobile = viewport.is("tablet");
  const { byTemplate } = useAppState();
  const footer = byTemplate("footer")[0];

  const renderLeftSide = () => {
    return (
      <div className={cl("left-side-content")}>
        {!isMobile && (
          <div className={cl("instagram-wrapper")}>
            <Link
              path="https://www.instagram.com/lepetitpali"
              className={cl("instagram")}
            >
              {instagram}
            </Link>
          </div>
        )}
        <div className={cl("newsletter")}>
          <NewsletterForm />
        </div>
      </div>
    );
  };

  const renderCenterContent = () => {
    return (
      <div className={cl("center-content")}>
        <AnimatePresence>
          <motion.div
            className={cl("number")}
            initial={{ x: isMobile ? 50 : 100 }}
            animate={{ x: 0 }}
            transition={{
              delay: 2.3,
              duration: 0.7,
            }}
          >
            {footer.blurb1 && footer.blurb1}
          </motion.div>
          <motion.div
            className={cl("logo")}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            style={{ originX: "50%", originY: "50%" }}
            transition={{
              delay: 2.4,
              duration: 0.7,
              ease: "easeIn",
            }}
          >
            <Link path="/">{mainLogo("70px", "115px")}</Link>
          </motion.div>
          <motion.div
            className={cl("link")}
            initial={{ x: isMobile ? -50 : -100 }}
            animate={{ x: 0 }}
            transition={{
              delay: 2.3,
              duration: 0.7,
            }}
          >
            <Link path={footer.blurb2 && footer.blurb2}>Contact Us</Link>
          </motion.div>
        </AnimatePresence>
      </div>
    );
  };

  const renderBottomContentMobile = () => {
    return (
      <div className={cl("bottom-content")}>
        <div>
          <Link path="https://www.instagram.com/" className={cl("instagram")}>
            {instagram}
          </Link>
        </div>
        <Link
          path="https://www.palisociety.com/"
          className={cl("right-side-content")}
          target="_self"
        >
          {byPaliSocietyLogo}
        </Link>
      </div>
    );
  };

  return (
    <footer className={cl("_")}>
      <div className={cl("container")}>
        {renderLeftSide()}
        {renderCenterContent()}
        {isMobile ? (
          renderBottomContentMobile()
        ) : (
          <Link
            path="https://www.palisociety.com/"
            className={cl("right-side-content")}
            target="_self"
          >
            {byPaliSocietyLogo}
          </Link>
        )}
      </div>
    </footer>
  );
}

export default Footer;
